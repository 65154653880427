import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'

type Props = {
  buttonContent: React.ReactNode
  listOrientation: 'left' | 'right'
  children: React.ReactNode
}

export default function DropdownMenu({ children, buttonContent, listOrientation }: Props) {
  return (
    <Menu as="div" className="relative inline-block text-left ">
      <div>
        <Menu.Button className="inline-flex w-full justify-center text-white focus:outline-none ">
          {buttonContent}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`${listOrientation}-0 absolute mt-2 min-w-[160px] origin-top-left divide-y divide-white/[0.15] bg-black-alt/[0.95] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
