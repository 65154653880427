import Image from 'next/image'

import { TitleLabelSecondary } from '../elements/labels/TitleLabels'

export default function LoveUsSection(): JSX.Element {
  return (
    <section className="container mb-8 flex flex-col items-center pb-6 pt-16 text-center md:pt-16">
      <TitleLabelSecondary>
        Why parents <span className="text-orange">love</span> our classes
      </TitleLabelSecondary>

      <div className="grid grid-cols-1 gap-6 md:w-[80%] md:grid-cols-3">
        <LoveUsCard
          title={`Taught by\nleading experts`}
          subtitle="Our classes are taught by certified experts, so you can feel confident you’re getting the best possible advice & plenty of pro tips "
          icon_name="/images/classes/icon-check.svg"
          icon_alt="Checkmark illustration"
        />
        <LoveUsCard
          title={`Watch anytime,\nanywhere`}
          subtitle="Our online classes are on-demand so that you can watch the videos at your own pace, on your schedule"
          icon_name="/images/classes/icon-play.svg"
          icon_alt="Play button illustration"
        />
        <LoveUsCard
          title={`Easy reference\nfor busy parents`}
          subtitle="Classes are broken down into bite-sized videos with downloadable handouts"
          icon_name="/images/classes/icon-handout.svg"
          icon_alt="Document illustration"
        />
      </div>
    </section>
  )
}

type LovUsCardProps = {
  title: string
  subtitle: string
  icon_name: string
  icon_alt: string
}
function LoveUsCard({ title, subtitle, icon_name, icon_alt }: LovUsCardProps) {
  return (
    <div className="flex flex-col items-start gap-4 rounded-md bg-grey-alt p-8 shadow">
      <Image src={icon_name} alt={icon_alt} height={45} width={45} />

      <div className="whitespace-pre-wrap text-left text-xl font-bold leading-6">{title}</div>
      <div className=" text-left text-sm ">{subtitle}</div>
    </div>
  )
}
