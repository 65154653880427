import Image from 'next/image'

import { TitleLabelTertiary } from '../elements/labels/TitleLabels'

export default function HappinessGuaranteeSection(): JSX.Element {
  return (
    <section className="flex flex-col items-center bg-grey-alt py-12 text-center">
      <div className="pb-6">
        <Image
          src="/images/classes/icon-guarantee.svg"
          alt="Illustration: satisfaction guaranteed"
          height={120}
          width={120}
        />
      </div>
      <TitleLabelTertiary>100% Customer Happiness, Guaranteed</TitleLabelTertiary>
      <div className="-mt-4 w-[80%] max-w-[400px] text-sm md:w-[50%]">
        If you are not completely satisfied with Tinyhood we’ll give you a full refund within 30
        days. We only ask that you give us feedback about what we could have done better so that we
        can continuously improve.
      </div>
    </section>
  )
}
