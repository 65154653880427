import { CheckIcon } from '@heroicons/react/outline'

type CheckmarkListItemProps = {
  boldText: string
  body: string
}

export default function CheckmarkListItem(props: CheckmarkListItemProps) {
  return (
    <li className="flex items-start pb-4 dark:text-white md:items-center">
      <CheckIcon className="mr-4 w-7 text-sea-foam md:mt-0" />
      <p className="mb-0 flex-1 font-light">
        <b>{props.boldText}</b> {props.body}
      </p>
    </li>
  )
}
