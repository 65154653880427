type TitleLabelProps = {
  children: React.ReactNode
  largeFont?: boolean
}

export function TitleLabelPrimary(props: TitleLabelProps) {
  const textblock = props.largeFont ? 'text-xl.3 md:text-5xl' : 'text-2.2xl  md:text-3xl'

  return <h1 className={`${textblock} mb-5 font-sans-new font-bold`}>{props.children}</h1>
}

export function TitleLabelSecondary(props: TitleLabelProps) {
  const textblock = props.largeFont ? 'text-xl.3 md:text-4xl' : 'text-xl.3 md:text-2.5xl'
  return <h2 className={`${textblock} mb-5 font-sans-new font-bold md:mb-10`}>{props.children}</h2>
}

export function TitleLabelTertiary(props: TitleLabelProps) {
  return <h3 className="mb-7.5 font-sans-new text-xl.2 font-bold md:text-2xl">{props.children}</h3>
}
