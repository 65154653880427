import { SwiperSlide } from 'swiper/react'
import BasicSwiper from './BasicSwiper'

import RoundedSubtitleCard from '../cards/RoundedSubtitleCard'

export type CategoryCardModel = {
  destinationURL: string
  imageURL: string
  title: string
}

type Props = {
  cards: CategoryCardModel[]
}

export function CategorySwiper({ cards }: Props) {
  return (
    <div>
      <div className="pb-6 font-super-new text-2xl uppercase text-grey-text">
        Featured categories
      </div>
      <BasicSwiper numVisible={4} numVisibleNarrow={2}>
        {cards.map((model) => {
          return (
            <SwiperSlide key={model.title}>
              <div className="relative h-[290px]">
                <RoundedSubtitleCard
                  destinationURL={model.destinationURL}
                  imageURL={model.imageURL}
                  title={model.title}
                />
              </div>
            </SwiperSlide>
          )
        })}
      </BasicSwiper>
    </div>
  )
}
