import { useQuery } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'
import { useAppContext } from '../state_management/context/appContext'
import api from './api'
import { ClassWatchProgress } from './models'

const QUERY_KEY = 'classWatchProgress'

async function getClassWatchProgress(excludeCompleted: boolean, config?: AxiosRequestConfig) {
  const response = await api.get<ClassWatchProgress[]>(
    `/api/v6/class_watch_progress?exclude_completed=${excludeCompleted}`,
    config
  )
  return response.data
}

export function useClassWatchProgress({
  excludeCompleted = false,
}: { excludeCompleted?: boolean } = {}) {
  const { isLoggedIn } = useAppContext()
  return useQuery({
    queryKey: [QUERY_KEY, excludeCompleted],
    queryFn: () => getClassWatchProgress(excludeCompleted),
    staleTime: 10000, // Expire after 10 seconds
    enabled: isLoggedIn,
  })
}
