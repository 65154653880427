import { TClass } from '~/lib/api/models'

import { SwiperSlide } from 'swiper/react'
import BasicSwiper from './BasicSwiper'

import TClassCard from '../cards/TClassCard'

type Props = {
  title: string
  tclasslist: TClass[]
  linkToWatchPage?: boolean
}

export default function TClassCardSwiper({ title, tclasslist, linkToWatchPage }: Props) {
  return (
    <div>
      <div className="pb-6 font-super-new text-2xl uppercase text-grey-text">{title}</div>

      <BasicSwiper numVisible={3} numVisibleNarrow={1}>
        {tclasslist.map((tclass) => {
          return (
            <SwiperSlide key={tclass.id}>
              <TClassCard tclass={tclass} linkToWatchPage={linkToWatchPage} />
            </SwiperSlide>
          )
        })}
      </BasicSwiper>
    </div>
  )
}
