import { TClass } from '~/lib/api/models'

import RoundedSubtitleCard from './RoundedSubtitleCard'

type Props = {
  tclass: TClass
  linkToWatchPage?: boolean
}

export default function TClassCard({ tclass, linkToWatchPage }: Props) {
  return (
    <div className="h-[190px]">
      <RoundedSubtitleCard
        destinationURL={
          linkToWatchPage ? `/classes/${tclass.slug}/watch` : `/classes/${tclass.slug}`
        }
        imageURL={tclass.image_url ?? ''}
        title={tclass.title}
      />
    </div>
  )
}
