import Image from 'next/image'
import Link from 'next/link'

type Props = {
  destinationURL: string
  imageURL: string
  title: string
}

export default function RoundedSubtitleCard({ destinationURL, imageURL, title }: Props) {
  const rounded = 'rounded-lg'
  return (
    <Link href={destinationURL}>
      <div className="relative flex h-full w-full shrink-0 select-none items-center justify-center  ">
        <div className={`${rounded} relative h-full w-full overflow-hidden`}>
          <Image
            className="bg-transparent object-cover"
            fill
            src={imageURL ?? ''}
            alt={`Preview image of Class: ${title}`}
            sizes="300px"
            loading={'eager'}
          />

          <div className="absolute h-full w-full  bg-gradient-to-b from-transparent via-black/[.45] to-black/[.95]  bg-clip-border"></div>

          <div className="absolute bottom-0 w-full p-4 text-lg font-bold text-white/[0.95]">
            {title}
          </div>
        </div>

        {/* 
			This extra top layer border is used purely for hiding the corner aliasing artifacts when trying
			to clip the contents with a rounded corner. 

			Couldn't find a workaround for this: 
			https://stackblitz.com/edit/nextjs-jvqzmx?file=styles/Home.module.css
		  */}
        <div
          className={`${rounded} absolute -bottom-px -left-px -right-px -top-px border-[2px] border-black-alt`}
        ></div>
      </div>
    </Link>
  )
}
