import { ClassWatchProgress, TClass } from '../api/models'

const CLASS_COMPLETE_PROGRESS_THRESHOLD = 0.99

export function getClassCompletedCount(
  classIdList: string[],
  allClassesWatchProgress: ClassWatchProgress[]
) {
  if (allClassesWatchProgress.length === 0) {
    return 0
  }

  const watchProgress: ClassWatchProgress[] = allClassesWatchProgress.filter((classProgress) =>
    classIdList.includes(classProgress.class_id)
  )

  return watchProgress.filter(
    (tclass) => tclass.class_progress >= CLASS_COMPLETE_PROGRESS_THRESHOLD
  ).length
}

export function includesTitle(title: string, classList: TClass[]) {
  const titleList = classList.map((tclass) => tclass.title)
  return titleList.includes(title)
}
