import Image from 'next/image'
import Link from 'next/link'
import { ReactNode } from 'react'
import { SecondaryDummyButton } from '../buttons/DummyButton'

type FeatureBannerProps = {
  title: string
  slug: string
  logo?: string | null
  logoAlt?: string | null
  subtitleLabel?: string
  backgroundImageURL: string
  backgroundImageAlt: string
  buttonText?: string
  children?: ReactNode
}

export default function FeatureBanner({
  title,
  slug,
  logo,
  logoAlt,
  subtitleLabel,
  backgroundImageURL,
  backgroundImageAlt,
  buttonText,
  children,
}: FeatureBannerProps) {
  return (
    <div className="relative w-full">
      <Link href={slug}>
        <div className="-z-1 absolute bottom-0 left-0 right-0 top-0">
          <Image
            fill
            className="rounded-br-3xl rounded-tl-3xl object-cover object-right"
            src={backgroundImageURL}
            alt={backgroundImageAlt}
            sizes="(max-width: 768px) 100vw, 940px"
            priority
          />
          <div className="absolute h-full w-full rounded-br-3xl rounded-tl-3xl bg-black opacity-40 md:w-[70%] md:bg-inherit md:bg-gradient-to-l md:from-transparent md:via-black/[.45] md:to-black/[.8] md:opacity-100" />
        </div>
        <div className="md:px-auto relative flex h-full flex-col gap-3 px-10 py-10 md:ml-10 md:w-[45%] md:pb-[100px]">
          <div className="relative h-[50px] w-[120px]">
            {logo && logoAlt && (
              <Image
                fill
                className="object-contain"
                src={logo}
                alt={logoAlt}
                sizes="120px"
                style={{ objectPosition: 'left' }}
              />
            )}
          </div>
          <div className="font-bold md:text-2.2xl">{title}</div>
          {subtitleLabel && <div className="hidden text-xs md:flex">{subtitleLabel}</div>}
          <span>{children}</span>
          <div className="w-full md:w-[260px]">
            <SecondaryDummyButton title={buttonText || 'Learn more'} />
          </div>
        </div>
      </Link>
    </div>
  )
}
