import { XIcon } from '@heroicons/react/outline'
import { InputHTMLAttributes, ReactNode } from 'react'
import { UseFormRegister } from 'react-hook-form'

type BasicInputType = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  error?: string
  register?: UseFormRegister<any>
  children?: ReactNode
  icon?: ReactNode
  clearableStateSetter?: (value: string) => void
}

export default function BasicInput({
  label = '',
  error = '',
  register,
  children,
  icon,
  clearableStateSetter,
  ...rest
}: BasicInputType) {
  const { name = '' } = rest

  return (
    <div className="relative flex flex-col items-center">
      {!!label && (
        <label
          className="w-full pb-[0.625rem] font-super-new text-xl text-grey-text"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      {icon && <span className="absolute inset-y-0 left-0 flex items-center pl-2">{icon}</span>}
      {clearableStateSetter && rest.value && (
        <span className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2">
          <XIcon className="h-4 w-4 text-gray-600" onClick={() => clearableStateSetter('')} />
        </span>
      )}
      <input
        {...rest}
        className={`${
          icon ? `pl-10 ` : ``
        }flex w-full rounded-[0.375rem] bg-white px-[1.125rem] py-[0.75rem] text-sm.3 font-semibold text-black outline-none`}
        {...(register && register(rest.name || '', { required: rest.required }))}
      />
      {children}
      {error && (
        <p className="mb-0 mt-1.7 min-h-[1.5rem] w-full text-[0.8125rem] text-orange">{error}</p>
      )}
    </div>
  )
}
