import { buttonLayout } from '~/lib/utils/button'

// A "button" with no click capability. Used when the banner that the button sits on is entirely clickable.

type DummyButtonProps = {
  title: string
  subtitle?: string
}

const buttonClasses =
  'block w-full rounded-md cursor-pointer whitespace-pre-wrap py-3.5 px-2.5 text-center text-sm font-bold md:py-4 md:text-lg'

export function SecondaryDummyButton({ title, subtitle }: DummyButtonProps) {
  return (
    <div className={`${buttonClasses} bg-white/[.38] text-white`}>
      {buttonLayout(title, subtitle)}
    </div>
  )
}
