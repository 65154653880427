import { useState } from 'react'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperProps } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'

interface BasicSwiperProps extends SwiperProps {
  numVisible: number
  numVisibleNarrow?: number
  children: React.ReactNode
  autoplay?: boolean
  autoHeight?: boolean
}

export default function BasicSwiper({
  numVisible,
  numVisibleNarrow,
  autoplay,
  autoHeight,
  children,
  ...swiperProps
}: BasicSwiperProps) {
  const [paginationEl, setPaginationEl] = useState<HTMLElement | null>(null)
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)

  const autoplayprops = autoplay
    ? {
        delay: 3000,
        pauseOnMouseEnter: true,
      }
    : false

  return (
    <div className="flex select-none flex-col gap-0">
      <div className="relative flex flex-row items-center overflow-visible">
        <div
          ref={(node) => setPrevEl(node)}
          className="absolute -left-[60px] bottom-0 top-0 flex hidden w-[40px] cursor-pointer items-center text-grey-text md:flex"
        >
          <ChevronLeftIcon className=" h-[40px] w-[40px] " />
        </div>
        <Swiper
          spaceBetween={30}
          navigation={{ prevEl: prevEl, nextEl: nextEl, disabledClass: 'opacity-30' }}
          slidesPerView={numVisibleNarrow ?? 1}
          slidesPerGroup={numVisibleNarrow ?? 1}
          autoHeight={autoHeight}
          breakpoints={{
            768: {
              slidesPerView: numVisible,
              slidesPerGroup: numVisible,
            },
          }}
          pagination={{
            el: paginationEl,
            clickable: true,
          }}
          autoplay={autoplayprops}
          modules={[Pagination, Navigation, Autoplay]}
          className="w-full"
          simulateTouch={false}
          {...swiperProps}
        >
          {children}
        </Swiper>
        <div
          ref={(node) => setNextEl(node)}
          className="absolute -right-[60px] bottom-0 top-0 flex hidden w-[40px] cursor-pointer items-center text-grey-text md:flex"
        >
          <ChevronRightIcon className=" h-[40px] w-[40px] " />
        </div>
      </div>
      <div
        ref={(node) => setPaginationEl(node)}
        className={`flex h-10 flex-row items-center justify-center`}
      />
    </div>
  )
}
