import { TitleLabelSecondary } from '../elements/labels/TitleLabels'

import GetStartedButton from '../elements/buttons/GetStartedButton'
import CheckmarkListItem from '../elements/lists/CheckmarkListItem'

export default function SubscriptionUpsellSection(): JSX.Element {
  const primaryButton = <GetStartedButton title={'Get Started'} area="Join now widget" />

  return (
    <section className="bg-white py-10 dark:bg-grey-alt md:py-20">
      <div className="container">
        <div className="grid grid-cols-12 gap-x-5">
          <div className="col-span-12 md:col-span-6">
            <TitleLabelSecondary>
              Join now for full access to Tinyhood&rsquo;s class library
            </TitleLabelSecondary>
            <div className="hidden max-w-[300px] flex-col items-center sm:flex-row sm:items-baseline md:flex">
              {primaryButton}
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="mb-12.2 md:mb-0">
              <div className="mb-5 block font-super-new text-lg font-normal uppercase text-sea-foam md:mb-8 md:text-2.2xl">
                WHAT&apos;S INCLUDED IN A MEMBERSHIP?
              </div>
              <ul>
                <CheckmarkListItem
                  boldText="On-demand classes"
                  body="for every stage of parenthood"
                />
                <CheckmarkListItem
                  boldText="Instruction from leading certified experts"
                  body="with years of hands-on experience in their fields"
                />
                <CheckmarkListItem
                  boldText="Downloadable class handouts,"
                  body="checklists and guides for easy reference"
                />
              </ul>
            </div>
          </div>
          <div className="col-span-12 md:hidden">{primaryButton}</div>
        </div>
      </div>
    </section>
  )
}
